.right_font
{
    font-family:'Public Sans', sans-serif;
}
.left_font
{
    font-family:'Century Schoolbook', Georgia, Times, 'Times New Roman', serif;
} 
.logofont
{
    font-family:'Playfair Display', Georgia, Times, 'Times New Roman', serif;
}